<template>
  <div>
    <HomePageAdmin v-if="hasAdminRole" />
    <HomePageStudent v-if="isStudent" />
    <HomePageTeacher v-if="isTeacher" />
  </div>
</template>

<script>
import HomePageAdmin from '@/modules/home/components/home-page-admin.vue';
import HomePageStudent from '@/modules/home/components/home-page-student.vue';
import HomePageTeacher from '@/modules/home/components/home-page-teacher.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'app-home-page',

  components: {
    HomePageAdmin,
    HomePageStudent,
    HomePageTeacher,
  },

  computed: {
    ...mapGetters({
      hasAdminRole: 'auth/hasAdminRole',
      isTeacher: 'auth/isTeacher',
      isStudent: 'auth/isStudent',
    }),
  },
};
</script>
