<template>
  <div>
    <p v-if="!showGraphs" class="home-bottom-message">{{message}}</p>
    <div v-if="showGraphs">
      <div class="skills-title">Communicative Skills</div>
      <el-row :gutter="16" class="skills-container">
        <el-col :lg="{span:10, offset: 2}" :md="12" :sm="24">
          <app-home-chart :config="lastCommunicativeBar" />
        </el-col>
        <el-col :lg="{span:10}" :md="12" :sm="24">
          <app-home-chart :config="lastCommunicativeDoughnut" />
        </el-col>
      </el-row>
      <div class="skills-title">Enabling Skills</div>
      <el-row :gutter="16" class="skills-container">
<!--        <el-col :lg="{span:10, offset: 2}" :md="12" :sm="24">-->
<!--          <app-home-chart :config="horizontalBar" />-->
<!--        </el-col>-->
        <el-col :lg="{span:10, offset: 2}" :md="12" :sm="24">
          <app-home-chart :config="lastEnablingPolar" />
        </el-col>
        <el-col :lg="{span:10}" :md="12" :sm="24">
          <app-home-chart :config="lastEnablingBar" />
        </el-col>
      </el-row>
      <div class="skills-title">Last Exams</div>
      <el-row :gutter="16" class="skills-container">
        <el-col v-if="showMultiGraphs" :lg="{span:10,offset: 2}" :md="12" :sm="24">
          <app-home-chart :config="lastFiveOverallBar" />
        </el-col>
        <el-col v-if="showMultiGraphs" :lg="{span:10}" :md="12" :sm="24">
          <app-home-chart :config="lastFiveCommunicativeLine" />
        </el-col>
      </el-row>

    </div>


    <p class="home-bottom-text">Ibn Haldun Test of English</p>
  </div>
</template>

<script>
import { i18n } from '@/i18n';
import HomeChart from '@/modules/home/components/home-chart.vue';
import { mapActions, mapGetters } from 'vuex';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  name: 'app-home-page-student',

  components: {
    [HomeChart.name]: HomeChart,
  },

  async created() {
    if (this.isMobile) {
      this.collapseMenu();
    }
    try {
      await this.doFetchFive();
      //console.log("Rows:",this.rows);
    } catch (e) {
      this.message = e.message;
    }
  },

  data() {
    return {
      lastExamScores: null,
      message: 'You have no exam results',
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      rows: 'examResult/list/rows',
      loading: 'examResult/list/loading',
    }),
    lastExam(){
      return this.rows[this.rows.length-1];
    },
    communicativeStatics(){
      let retVal = {};
      retVal.skills = ['reading','writing','listening','speaking'];
      retVal.labels = retVal.skills.map(cs => i18n('home.charts.exam.'+cs));
      retVal.colors = ['#FF6384','#36A2EB','#FFCE56','#4CBA6A'];
      retVal.lastExamData = retVal.skills.map(cs => this.lastExam[cs]);
      return retVal;
    },
    enablingStatics(){
      let retVal = {};
      retVal.skills = ['grammar','oralFluency','pronunciation','spelling','vocabulary','writtenDiscourse'];
      retVal.labels = retVal.skills.map(cs => i18n('home.charts.exam.'+cs));
      retVal.colorSame = ['#FF6384','#FF6384','#FF6384','#FF6384','#FF6384','#FF6384'];//,'#36A2EB','#FFCE56','#4cba6a'];
      retVal.colors = ['#4CBA6A','#F36F13','#FF6384','#36A2EB','#FFCE56','#063951'];//,'#36A2EB','#FFCE56','#4cba6a'];
      retVal.lastExamData = retVal.skills.map(cs => this.lastExam.results[cs]);
      return retVal;
    },
    showGraphs() {
      //console.log("Sayfada rows: " + JSON.stringify(this.rows))
      return this.rows && this.rows.length !== 0;
    },
    showMultiGraphs() {
      return this.rows && this.rows.length >= 2;
    },
    circlePlugins() {
      return {
        datalabels: {
          backgroundColor: function(context) {
            return context.dataset.backgroundColor;
          },
          anchor: 'end',
          borderColor: 'white',
          borderRadius: 25,
          borderWidth: 1,
          // color: 'white',
          color: function(context) {
            var index = context.dataIndex;
            var deger =
              context.dataset.backgroundColor[index];
            return deger == '#FFCE56' ? '#545454' : 'white';
          },
          display: function(context) {
            var dataset = context.dataset;
            var count = dataset.data.length;
            var value = dataset.data[context.dataIndex];
            return value > count * 1.5;
          },
          font: {
            size: 14,
            weight: 'bold',
          },
          formatter: Math.round,
        },
      };
    },

    lastCommunicativeBar() {
      return {
        type: 'bar',
        title: `${this.lastExam.name} - `,
        titleImp: `${i18n('home.charts.score')}: ${
          this.lastExam.aftermath
        }`,
        underlinetext: true,
        data: {
          labels: this.communicativeStatics.labels,
          datasets: [
            {
              label: 'Score',
              backgroundColor: this.communicativeStatics.colors,
              data: this.communicativeStatics.lastExamData,
            },
          ],
        },
        plugins: [ChartDataLabels],
        options: {
          plugins: this.circlePlugins,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  suggestedMax: 90,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontSize: 16,
                },
              },
            ],
          },
          layout: {
            padding: {
              bottom: 0,
              top: 15,
              right: 0,
              left: 0,
            },
          },
          legend: { display: false },
        },
      };
    },
    lastCommunicativeDoughnut() {
      if (!this.showGraphs) {
        return null;
      }
      return {
        type: 'doughnut',
        title: this.lastExam.name,
        test: 'abcd',
        data: {
          labels: this.communicativeStatics.labels,
          datasets: [
            {
              data: this.communicativeStatics.lastExamData,
              backgroundColor: this.communicativeStatics.colors,
              hoverBackgroundColor: this.communicativeStatics.colors,
            },
          ],
        },
        plugins: [ChartDataLabels],
        options: {
          plugins: this.circlePlugins,
          layout: {
            padding: {
              bottom: 15,
              top: 0,
              right: 0,
              left: 0,
            },
          },
          legend: {
            display: true,
            labels: {
              fontSize: 16,
            },
          },
        },
      };
    },
    lastFiveOverallBar() {
      if (!this.showGraphs) {
        return null;
      }
      return {
        type: 'bar',
        title: i18n('home.charts.lastfiveexam'),
        data: {
          labels: this.rows.map((s) => s.name),
          datasets: [
            {
              label: i18n('home.charts.totalscores'),
              backgroundColor: '#36A2EB',
              borderColor: '#696b6a',
              borderWidth: 1,
              hoverBackgroundColor: '#059BFF',
              hoverBorderColor: '#059BFF',
              data: this.rows.map((s) => s.aftermath),
            },
          ],
        },
        plugins: [ChartDataLabels],
        options: {
          plugins: this.circlePlugins,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: true,
              },
            ],
            yAxes: [
              {
                display: true,
                ticks: {
                  beginAtZero: true,
                  suggestedMax: 90,
                },
              },
            ],
          },
        },
      };
    },
    lastFiveCommunicativeLine() {
      if (!this.showGraphs) {
        return null;
      }
      let datasets = [];
      this.communicativeStatics.skills.forEach((cs,i)=>{
         datasets.push(
             {
                 label: i18n('home.charts.exam.'+cs),
                 data: this.rows.map((s) => s[cs]),
                 fill: false,
                 backgroundColor: this.communicativeStatics.colors[i],
                 borderColor: this.communicativeStatics.colors[i],
                 hoverBackgroundColor: this.communicativeStatics.colors[i],
                 hoverBorderColor: this.communicativeStatics.colors[i],
             }
         )
      });
      return {
        type: 'line',
        title: i18n('home.charts.lastfiveexam'),
        options: {
          responsive: true,
          tooltips: {
            mode: 'label',
          },
          elements: {
            line: {
              fill: false,
            },
          },
          scales:{
            yAxes: [
              {
                ticks: {
                  //beginAtZero: true,
                  //suggestedMax: 90,
                },
              }
            ]
          }
        },
        data: {
          labels: this.rows.map((s) => s.name),
          datasets,
        },
      };
    },
    lastEnablingBar() {
      return {
        type: 'bar',
        //title: `${this.lastExam.name}`,
        //titleImp: `${i18n('home.charts.score')}`,
        //underlinetext: false,
        data: {
          labels: this.enablingStatics.labels,
          datasets: [
            {
              label: 'Score',
              backgroundColor: this.enablingStatics.colorSame,
              data: this.enablingStatics.lastExamData,
            },
          ],
        },
        plugins: [ChartDataLabels],
        options: {
          plugins: this.circlePlugins,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  suggestedMax: 90,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontSize: 16,
                },
              },
            ],
          },
          layout: {
            padding: {
              bottom: 0,
              top: 15,
              right: 0,
              left: 0,
            },
          },
          legend: { display: false },
        },
      };
    },
    lastEnablingPolar() {
      return {
        type: 'polarArea',
        title: `${this.lastExam.name}`,
        data: {
          labels: this.enablingStatics.labels,
          datasets: [
            {
              data: this.enablingStatics.lastExamData,
              backgroundColor: this.enablingStatics.colors,
            },
          ]
        },
        plugins: [ChartDataLabels],
        options: {
            plugins: this.circlePlugins,
        }
      };
    },

    horizontalBar() {
      return {
        type: 'bar',
        options: {
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                display: true,
              },
            ],
          },
        },
        data: {
          labels: [
            i18n('home.charts.months.1'),
            i18n('home.charts.months.2'),
            i18n('home.charts.months.3'),
            i18n('home.charts.months.4'),
            i18n('home.charts.months.5'),
            i18n('home.charts.months.6'),
          ],
          datasets: [
            {
              label: i18n('home.charts.red'),
              backgroundColor: 'rgba(255,99,132,0.2)',
              borderColor: 'rgba(255,99,132,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(255,99,132,0.4)',
              hoverBorderColor: 'rgba(255,99,132,1)',
              data: [65, 59, 80, 81, 56, 55, 40],
            },
          ],
        },
      };
    },

  },

  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
      doFetchFive: 'examResult/list/doFetchFive',
    }),
  },
};
</script>

<style>
.chart-title {
  font-size: 16px;
}
</style>
<style scoped>
.el-col {
  margin-bottom: 16px;
}
.home-bottom-message {
  text-align: center;
  color: grey;
  height: 80vh;
}
.home-bottom-text {
  width: 100%;
  text-align: center;
  color: grey;
  font-size: 13px;
}
.hidden {
  pointer-events: none;
  opacity: 0.4;
}
.skills-title {
  width: 200px;
  height: 20px;
  padding-top: 7px;
  background-color: white;
  border-top-right-radius: 50px 60px;
  border-top-left-radius: 50px 60px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  margin-left: -8px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
}
.skills-container {
  width: 100%;
  height: 100%;
  padding-top: 10px;
  background-color: white;
  margin-bottom: 30px;
}
</style>
